export const experiencesJSON = [
  {
    experienceID: "8",
    company: "TOFAS",
    date: "July 2017 - July 2017",
    title: "Intern",
    department: "Production Department",
    website: "https://tofas.com.tr/Pages/default.aspx",
    sorting: "99",
    responsibilities: [],
    projects: [],
  },
  {
    experienceID: "9",
    company: "QNB Finansbank",
    date: "August 2017 - September 2017",
    title: "Intern",
    department: "Uludag Branch",
    website: "https://www.qnbfinansbank.com/",
    sorting: "98",
    responsibilities: [],
    projects: [],
  },
  {
    experienceID: "10",
    company: "ML Components GmbH",
    date: "September 2018 - September 2018",
    title: "Erasmus Intern",
    department: "Sales Department",
    website: null,
    sorting: "97",
    responsibilities: [],
    projects: [],
  },
  {
    experienceID: "11",
    company: "Sahince Otomotiv",
    date: "December 2018 - January 2019",
    title: "Intern",
    department: "Logistics and Planning Department",
    website: "https://www.sahince.com.tr/",
    sorting: "96",
    responsibilities: [],
    projects: [],
  },
  {
    experienceID: "12",
    company: "Temizis Ofset & Dijital",
    date: "May 2019 - November 2020",
    title: "IT Specialist - Software Developer",
    department: "Information Technology Department",
    website: "http://temizisofset.com.tr/",
    sorting: "89",
    responsibilities: [
      "Winform Application development (.NET Framework)",
      "MS SQL Server",
      "Developing apps that will facilitate the operation in the company with Excel VBA",
      "Software, hardware and network support",
    ],
    projects: [
      "TOD is basically an application that manages the operation between the purchasing, warehouse, production and shipping departments to plan and track the use of resources. I have developed the application in Visual Studio with .NET Framework and C#. I used MS SQL Server database",
      "TOD Costing is a module used to generate a price for the finished product. The application has .NET Framework and SQLite",
      "TOD Pricing is a support module for LOGO accounting application. It enables the transfer of information between cost analysts and accountants and is used for pricing of waybills. It has .NET Framework and MS SQL Server database",
      "Also, I developed applications such as raw material tracking, production reporting, product revision control, product price calculation and control in Excel VBA",
    ],
  },
  {
    experienceID: "13",
    company: "Durak Tekstil",
    date: "December 2020 - March 2022",
    title: "Software Engineer",
    department: "Information Technology Department",
    website: "https://www.durak.com/",
    sorting: "88",
    responsibilities: [
      "Web and mobile application development (.NET Webforms, React.js, React Native, MUI, Git)",
      "MS SQL Server",
      "Software, hardware and network support",
    ],
    projects: [
      "DurakWeb is a web application developed with ASP.NET Webforms. Processes such as new project approving, product commissioning, KPI follow up, employee performance tracking, quality assurance manage on this portal.",
      "Durak App is a mobile application developed with React Native to help sales department for products\u2019 information and unit conversion",
      "D-Report is a report module of DurakWeb that is developed with ASP.Net Webforms and Devexpress Tools",
      "DurakTube is an video education module of DurakWeb",
      "Durak Mail App is an auto mail application developed with .NET Framework. It sends daily Excel reports to the relevant e-mail adresses on scheduled times",
    ],
  },
  {
    experienceID: 14,
    company: "Harput Holding",
    date: "September 2022 - March 2023",
    title: "Frontend Developer",
    department: "Information Technology",
    website: "https://www.harputholding.com.tr/",
    sorting: "87",
    responsibilities: [
      "Frontend development (React.js, MUI, Git)",
      "Supporting to backend development processes (.NET 6)",
    ],
    projects: [
      "Harput ERP (Enterprise Resource Planning)",
      "Limon CRM (Customer Relationship Management)",
      "Scada is an app that developed to get data from food production machine and to provide production report",
    ],
  },
  {
    experienceID: 16,
    company: "Hogarth Worldwide",
    date: "March 2023 - July 2023",
    title: "Frontend Developer",
    department: "Elementary Department",
    website: "https://www.hogarth.com/",
    sorting: "86",
    responsibilities: [
      "Frontend development of customers' websites and web applications (HTML, CSS, JS, Docker, SVN)",
    ],
    projects: [],
  },
  {
    experienceID: 18,
    company: "",
    date: "March 2023 - April 2023",
    title: "Freelance Frontend Developer",
    department: "",
    website: "",
    sorting: "85",
    responsibilities: ["Frontend development (React, MUI, Typescript, JIRA, Git)"],
    projects: [
      "A web project that offers Web 3.0 and Blockchain technologies and allows payment with crypto assets",
    ],
  },
  {
    experienceID: 20,
    company: "Proses Yazılım",
    date: "July 2023 - September 2023",
    title: "Full Stack Developer",
    department: "Software Development",
    website: "https://www.prosesyazilim.com/",
    sorting: "86",
    responsibilities: [
      "Frontend development (React.js, MUI, Git)",
      "Web API development (.NET 6)",
      "Table structures of applications (MS SQL, MySql)",
    ],
    projects: ["Product sales portal for a customer"],
  },
  {
    experienceID: 21,
    company: "Durak Tekstil",
    date: "October 2023 - present",
    title: "Software Unit Manager",
    department: "IT",
    website: "https://www.durak.com/",
    sorting: "87",
    responsibilities: [
      "Project Management",
      "Organizing trainings for users",
      "React.js, React Native, .NET 6, MSSQL",
    ],
    projects: ["DurakWeb", "BT Destek", "Durak App", "Dashboard Designer", "Meeting Room Scheduler"],
  },
];
